import React from 'react';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import {
  Section,
  ContentWrapper,
  TextContent,
} from '../page.style';

export default () => {
  return (
    <Section>
      <Container>
        <ContentWrapper>
          <TextContent>
            <Heading content="Imprussum"/>
            <div>
              <p>
                <strong>easypickup UG (haftungsbeschränkt)</strong><br/>
              </p>
              <address>
                Papenstraße 5A, <br/>
                22089 Hamburg<br/>
                Deutschland<br/>
              </address>
              <strong>Telefon:</strong> 0176 71201193<br/>
              <strong>E-Mail:</strong> hallo@easyresto.de
              <p>
                <strong>Geschäftsführer</strong>: Michael Fock<br/>
                {/*<strong>Gesellschaft wird vertreten durch: Michael Fock</strong>*/}
              </p>
              <p>
                Handelsregister: <strong>Amtsgericht Hamburg</strong><br/>
                Handelsregister: <strong>HRB 162577</strong><br/>
                Umsatzsteuer-ID: <strong>DE332746678</strong>
              </p>
            </div>
          </TextContent>
        </ContentWrapper>
      </Container>
    </Section>
  );
};